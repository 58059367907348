import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/HelloWorld'
import Layout from '@/layout'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
  routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
			meta: {
				title: 'Home'
			}
		},
		{
			path: '/acti',
			component: Layout,
			children: [{
				path: 'MemberRule',
				name: 'MemberRule',
				component: () => import('@/views/MemberRule/index.vue'),
				meta: {
					title: '__memberRule'
				}
			},
			{
				path: 'BaccaratRule',
				name: 'BaccaratRule',
				component: () => import('@/views/BaccaratRule/index.vue'),
				meta: {
					title: '__baccaratRule'
				}
			},
			{
				path: 'NiuNiuRule',
				name: 'NiuNiuRule',
				component: () => import('@/views/NiuNiuRule/index.vue'),
				meta: {
					title: '__niuNiuRule'
				}
			},
			{
				path: 'OnlineCustomerService',
				name: 'OnlineCustomerService',
				component: () => import('@/views/OnlineCustomerService/index.vue'),
				meta: {
					title: 'OnlineCustomerService'
				}
			},
			{
				path: 'AgentCustomAct',
				component: Layout,
				meta: { title: 'AgentCustomAct' },
				children: [{
					path: ':id(\\d+)',
					name: 'AgentCustomAct',
					component: () => import('@/views/AgentCustomAct/index.vue'),
					meta: { title: 'AgentCustomAct' }
				}, {
					path: 'lobby/:id(\\d+)',
					name: 'agentCustomActLobby',
					component: () => import('@/views/AgentCustomAct/lobby.vue'),
					meta: { title: 'agentCustomActLobby' }
				}, {
					path: 'rank/:id(\\d+)',
					name: 'agentCustomActRank',
					component: () => import('@/views/AgentCustomAct/rank.vue'),
					meta: { title: 'agentCustomActRank' }
				}, {
					path: 'register/:id(\\d+)',
					name: 'agentCustomActRegister',
					component: () => import('@/views/AgentCustomAct/register.vue'),
					meta: { title: 'agentCustomActRegister' }
				}]
			},
			{
				path: 'AppDownload',
				name: 'AppDownload',
				component: () => import('@/views/AppDownload/index.vue'),
				meta: {
					title: 'AppDownload'
				}
			},
			// {
			// 	path: 'AppDownloadBonus',
			// 	name: 'AppDownloadBonus',
			// 	component: () => import('@/views/AppDownloadBonus/index.vue'),
			// 	meta: {
			// 		title: 'AppDownloadBonus'
			// 	}
			// },
			// {
			// 	path: 'XmasAct2022',
			// 	component: Layout,
			// 	meta: { title: '2022_XmasAct' },
			// 	children: [{
			// 		path: ':id(\\d+)',
			// 		name: 'XmasAct2022',
			// 		component: () => import('@/views/2022_Xmas/index.vue'),
			// 		meta: { title: '2022_XmasAct' }
			// 	}, {
			// 		path: 'Report/:id(\\d+)',
			// 		name: 'XmasActReport2022',
			// 		component: () => import('@/views/2022_Xmas/report.vue'),
			// 		meta: { title: '2022_XmasActReport' }
			// 	}]
			// },
			// {
			// 	path: 'T9GodOfGamblersAct',
			// 	component: Layout,
			// 	meta: { title: 'T9GodOfGamblersAct' },
			// 	children: [{
			// 		path: ':id(\\d+)',
			// 		name: 'T9GodOfGamblersAct',
			// 		component: () => import('@/views/T9GodOfGamblers/index.vue'),
			// 		meta: { title: 'T9GodOfGamblersAct' }
			// 	}, {
			// 		path: 'Report',
			// 		name: 'T9GodOfGamblersActReport',
			// 		component: () => import('@/views/T9GodOfGamblers/report.vue'),
			// 		meta: { title: 'T9GodOfGamblersActReport' }
			// 	}]
			// },
			// {
			// 	path: 'T9GodOfGamblersActRank/:id(\\d+)',
			// 	name: 'T9GodOfGamblersActRank',
			// 	component: () => import('@/views/T9GodOfGamblers/index.vue'),
			// 	meta: {
			// 		title: 'T9GodOfGamblersActRank'
			// 	}
			// },
			// {
			// 	path: 'BaccaratTournament',
			// 	component: Layout,
			// 	meta: { title: '__baccaratTournament' },
			// 	children: [{
			// 		path: ':id(\\d+)',
			// 		name: 'BaccaratTournament',
			// 		component: () => import('@/views/BaccaratTournament/index.vue'),
			// 		meta: { title: '__baccaratTournament' }
			// 	}, {
			// 		path: 'rank/:id(\\d+)',
			// 		name: 'BaccaratTournamentRank',
			// 		component: () => import('@/views/BaccaratTournament/index.vue'),
			// 		meta: { title: '__baccaratTournament' }
			// 	}, {
			// 		path: 'Report',
			// 		name: 'BaccaratTournamentReport',
			// 		component: () => import('@/views/BaccaratTournament/report.vue'),
			// 		meta: { title: '__baccaratTournament' }
			// 	}]
			// },
		]},
		{
			path: '*',
			name: '404',
			component: Layout,
			meta: {
				title: '404'
			},
			children: [{
				path: '',
				name: '404',
				component: () => import('@/views/404'),
				meta: { title: '404' }
			}]
		},
	]
})

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
			document.title = to.meta.title
	}
	next();
})

// 注意這邊 export default router 對應到 main.js 的 import
export default router
