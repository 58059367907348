<template>
  <section>
    <keep-alive >
      <router-view />
    </keep-alive>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
  }
}
</script>
