import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./styles/index.scss";
import VueQRCodeComponent from 'vue-qrcode-component'
import { i18n } from "./lang/lang"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

Vue.component('qr-code', VueQRCodeComponent)

Vue.config.productionTip = false
Vue.prototype.$stringFormat = function stringFormat(formatted, args) {
  for (let i = 0; i < args.length; i++) {
    const regexp = new RegExp('\\{' + i + '\\}', 'gi')
    formatted = formatted.replace(regexp, args[i])
  }
  return formatted
}

// https://www.npmjs.com/package/vue-toastification
// https://vue-toastification.maronato.dev/
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 3,
  newestOnTop: true
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')