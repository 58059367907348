<template>
  <div class="app-container">
    <app-main class="appMain" />
  </div>
</template>

<script>
import { AppMain } from './components'

export default {
  name: 'Layout',
  components: {
    AppMain,
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
