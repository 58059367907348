import Vue from 'vue'
import VueI18n from 'vue-i18n'
import twLocale from './tw.json'
import cnLocale from './cn.json'
import thLocale from './th.json'
import jpLocale from './jp.json'
import vnLocale from './vn.json'
import koLocale from './ko.json'
import enLocale from './en.json'

// 全域啟用i18n
Vue.use(VueI18n)

// 定義語系使用的key name，我們可以將外部的語系檔案在 messages 中引用
const messages = {
  cn: {
    ...cnLocale,
  },
  tw: {
    ...twLocale,
  },
  th: {
    ...thLocale,
  },
  jp: {
    ...jpLocale,
  },
  vn: {
    ...vnLocale,
  },
  ko: {
    ...koLocale,
  },
  en: {
    ...enLocale,
  }
}

const languageKey = 'language' // 定義寫入Cookies時使用的名稱

// 定義取得Cookies的函數並匯出
export const getLanguage = () => {
  const chooseLanguage = localStorage.getItem(languageKey) // 定義取得Cookies的常數
  if (chooseLanguage) { return chooseLanguage }

  // 如果沒有選擇任何一項語言
  const language = (navigator.language).toLowerCase() // 將瀏覽器回傳語value字串統一為小寫
  const locales = Object.keys(messages) // { zh_chs, zh_cht }
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en' // 沒選擇語言的時候，預設轉到英文
}

// 定義設置Cookies的函數並匯出，之後我們會在各組件中呼叫它來使用
export const setLanguage = (language) => {
  if (!messages[language]) {
    language = 'tw'
  }
  i18n.locale = language
  localStorage.setItem(languageKey, language)
}

export const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  messages,
  // 避免i18n會因為一點小事刷你整頁的console
  silentTranslationWarn: true
})

